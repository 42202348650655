import React from 'react'
import styled from 'styled-components'
import { Services } from '../components/index'
import { StyledTitle } from '../components-styled/index'
import Layout from "../components/layout"
import SEO from "../components/seo"


const NotFoundPage = () => {
  return (
    <Layout>
      <SEO title="404" />
      <S.NotFoundPage>
        <div>
          <div style={{ marginBottom: '6rem' }}>
            <StyledTitle id="404" heading>NOT FOUND</StyledTitle>
          </div>
          <div>
            <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
          </div>
        </div>
      </S.NotFoundPage>
      <Services />
    </Layout>
  )
}


const S = {
  NotFoundPage: styled.section`
    width: 100%;
    height: 100%;
    > div {
      max-width: 1900px;
      margin: 0 auto;
      padding: calc(4% + 6.5rem) 5%;
    }
    @media(min-width: 850px) {
      > div {
        padding-bottom: 7rem;
      }
    }
  `
}


export default NotFoundPage
